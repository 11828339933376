@import "../../assets/css/variables.module.scss";

.footer-59391 {
    // border-top: 1px solid #efefef;
    background-color: $primary-dark;
    font-family: "Poppins", sans-serif;
    padding: 4rem 0;
}
.footer-59391 .site-logo a {
    font-size: 30px;
    color: $primary;
    font-weight: 900;
}
.footer-59391 .nav-links li {
    display: inline-block;
}
.footer-59391 .nav-links li a {
    color: $lite;
    padding: 10px;
    font-size: 16px;
}
.footer-59391 .nav-links li a:hover {
    color: $primary2;
}
.footer-59391 .nav-links.nav-left li:first-child {
    padding-left: 0;
}
.footer-59391 .nav-links.nav-right li:last-child {
    padding-right: 0;
}

.footer-59391 .subscribe .form-group button:focus,
.footer-59391 .subscribe .form-group button:active {
    outline: none;
}
.footer-59391 .social li {
    display: inline-block;
    position: relative;
}
.footer-59391 .social li a {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
    padding: 0;
    color: #333333;
}

.facebook {
    background: #3b5998;
    color: $lite !important;
}

.facebook:hover {
    background: $lite;
    color: #3b5998 !important;
}

.instagram {
    background: #c13584;
    color: $lite !important;
}
.instagram:hover {
    background: $lite;
    color: #c13584 !important;
}

.twitter {
    background: #589541;
    color: $lite !important;
}

.twitter:hover {
    background: $lite;
    color: #589541 !important;
}



.footer-59391 .social li a > span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.footer-95942 .nav-links li {
    display: block;
    margin-bottom: 10px;
}
.footer-95942 .nav-links li a {
    font-size: 14px;
    color: #777;
}

.footer-95942 .social.app {
    font-size: 13px;
}
.footer-95942 .social li {
    display: inline-block;
}
.footer-95942 .social li a {
    display: inline-block;
    padding: 10px;
    color: #ccc;
}

.footer-95942 .social.nav-left li:first-child a {
    padding-left: 0px;
}
.footer-95942 .social.nav-right li:last-child a {
    padding-right: 0px;
}
.footer-95942 h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-48201 {
    padding: 7rem 0;
    background-color: #000;
    color: #777;
}
.footer-48201 .footer-site-logo {
    font-size: 1.5rem;
    color: #fff;
}
.footer-48201 .nav-links li {
    margin-bottom: 10px;
}
.footer-48201 .nav-links li a {
    color: gray;
}
.footer-48201 .nav-links li a:hover {
    color: #fff;
}
.footer-48201 .btn.btn-tertiary {
    background-color: #e42c64;
    color: #fff;
    border-radius: 30px;
    border: none;
    padding: 10px 20px;
}
.footer-48201 .social li {
    display: inline-block;
}
.footer-48201 .social li a {
    color: gray;
    padding: 7px;
}
.footer-48201 .social li a:hover {
    color: #fff;
}
.footer-48201 .copyright {
    border-top: 1px solid #404040;
}

.footer-99382 .social li {
    display: inline-block;
}

.footer-16371 {
    padding: 7rem 0;
    font-family: "Poppins", sans-serif;
}

.footer-16371 .nav-links li {
    display: inline-block;
}
.footer-16371 .nav-links li a {
    padding: 10px;
    color: #000;
}

.footer-16371 .social h3 {
    font-weight: bold;
    font-size: 18px;
    color: #000;
}
.footer-16371 .social ul li {
    display: inline-block;
}

.footer-39201 .nav-links li {
    display: block;
    margin-bottom: 10px;
}

.footer-39201 .social li {
    display: inline-block;
}
.footer-39201 .social li a {
    display: inline-block;
    padding: 10px;
    color: #9d9d9d;
}

.footer-time {
    color: $lite !important;
    text-transform: capitalize;
}

.footer-address {
    font-size: 17px;
    color: $lite !important;
}

.footer-phone {
    font-size: 17px;
    color: $lite !important;
}

.footer-email {
    font-size: 17px;
    color: $lite !important;
}

.footer-about{
    font-size: 20px;
    color: $lite  !important;
}

.footer-title{
    font-size: 26px !important;
    color: $lite !important;
    font-weight: 600;
}


// start of code footer 3


.footer-32892 {
    background-color: #202126;
    padding: 2.5rem 0;
    color: #888;
    font-size: 16px;
}
.footer-32892,
.footer-32892 h3 {
    font-family: "Poppins", sans-serif;
}
.footer-32892 h3 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
}
.footer-32892 p {
    font-family: "Source Serif Pro", serif;
}
.footer-32892 .row.gallery {
    margin-right: -5px;
    margin-left: -5px;
}
.footer-32892 .row.gallery > [class^="col-"],
.footer-32892 .row.gallery > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.footer-32892 .gallery a:hover {
    opacity: 0.5;
}
.footer-32892 .quick-info li {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
}


.footer-32892 .footer-menu-wrap {
    border-top: 1px dashed  $lite;
    padding-top: 2rem !important;
    margin-top: 1.5rem;
}

.footer-32892 .footer-menu li {
    display: inline-block;
}
@media (max-width: 767.98px) {
    .footer-32892 .footer-menu li {
        display: block;
    }
}
.footer-32892 .footer-menu li a {
    padding: 10px;
    display: inline-block;
    color: #fff;
}
@media (max-width: 767.98px) {
    .footer-32892 .footer-menu li a {
        display: block;
        padding-left: 0px;
    }
}
.footer-32892 .footer-menu li:first-child a {
    padding-left: 0;
}
.footer-32892 .site-logo {
    color: #fff;
    font-size: 20px;
}
